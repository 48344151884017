import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FreightTemplate from '../templates/freight-template';

const Freight = ({ location, data }) => {
	const content = data.pagesJson;
	const path = location.pathname;

	return (
		<Layout>
			<SEO title="Freight" description={content.description} />
			<FreightTemplate copy={content} path={path} />
		</Layout>
	);
};

export default Freight;

export const pageQuery = graphql`
	query FreightPageQuery {
		pagesJson(page: { eq: "freight" }) {
			description
			masthead {
				title
				img
			}
			...servicesCopy
			...services
			form {
				title
				phone {
					format
					raw
				}
				img
			}
		}
	}
`;
